import React from 'react';
import { Container } from './CardGrid.styled';
import { BaseOffsetsUI, BaseUI, IMetrika } from '@/interfaces';
import Card1, { Card1Props } from '@/componentsNew/blocks/Cards/Card1/Card1';
import Card4, { Card4Props } from '../Cards/Card4/Card4';

interface CardGridProps extends BaseUI, BaseOffsetsUI, IMetrika {
    firstCard?: Card4Props;
    cards: Card1Props[];
}

const angles = [
    ['top-right'],
    ['top-left', 'bottom-right'],
    ['top-right', 'bottom-left'],
    ['top-left'],
    ['bottom-right'],
    ['top-right', 'bottom-left'],
    ['top-left', 'bottom-right'],
    ['bottom-left']
];

const images = ['/images/new/CardGrid/1.jpg', '/images/new/CardGrid/2.jpg', '/images/new/CardGrid/3.jpg', '/images/new/CardGrid/4.jpg'];

const CardGrid: React.FC<CardGridProps> = ({ className, firstCard, cards, metriksSample }) => {
    return (
        <Container className={className}>
            {firstCard && <Card4 {...firstCard} />}

            {cards.map((card, index) => (
                <Card1
                    key={index}
                    {...card}
                    angles={card.angles ? card.angles : angles[index % angles.length]}
                    image={card.image ? card.image : images[index % images.length]}
                    clickOnWrapper={metriksSample}
                />
            ))}
        </Container>
    );
};

export default CardGrid;
