import React from 'react';
import dynamic from 'next/dynamic';
import { Container, TextContainer, CardsContainer, BottomContainer, CompaniesContainer, CompaniesImage } from './WhyChooseSection.styled';
import Angle from '@/icons/cards/new/angle.svg';
import PrimaryCard, { IPrimaryCard } from '@/componentsNew/blocks/PrimaryCard/PrimaryCard';
import { SwiperSlide } from 'swiper/react';
import { BaseOffsetsUI } from '@/interfaces';
import useIsMatches from '@/hooks/useIsMatches';
const SimpleSlider = dynamic(() => import('@/components/common/SimpleSlider/SimpleSlider'));

export interface WhyChooseSectionState extends BaseOffsetsUI {
    title: string;
    span: string;
    paragraphs: string[];
    link?: {
        title: string;
        href: string;
    };
    companies?: {
        title: string;
        icons: string[];
    };

    cards: IPrimaryCard[];
}

const WhyChooseSection: React.FC<WhyChooseSectionState> = ({
    title,
    span,
    paragraphs,
    link,
    companies,
    cards,
    topOffset,
    bottomOffset
}) => {
    const isMobile = useIsMatches('md');
    const swiperConfig = {
        allowTouchMove: true,
        spaceBetween: isMobile ? 12 : 16
    };

    const chunkArray = (array: IPrimaryCard[], size: number) => {
        const result: IPrimaryCard[][] = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const chunkedCards = chunkArray(cards, 3);

    return (
        <Container topOffset={topOffset} bottomOffset={bottomOffset}>
            <TextContainer>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                {span && <span>{span}</span>}
                <ul>
                    {paragraphs.map((item, i) => (
                        <li key={i}>
                            <p dangerouslySetInnerHTML={{ __html: item }} />
                        </li>
                    ))}
                </ul>

                {link || companies ? (
                    <BottomContainer>
                        {link && (
                            <a href={link.href} target="_blank" rel="noreferrer">
                                {link.title}
                                <Angle />
                            </a>
                        )}

                        {companies && (
                            <CompaniesContainer>
                                <span dangerouslySetInnerHTML={{ __html: companies.title }} />
                                {companies.icons && (
                                    <ul>
                                        {companies.icons.map((icon, i) => (
                                            <li key={i}>
                                                <CompaniesImage src={icon} alt={title} width={64} height={64} objectFit="contain" />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </CompaniesContainer>
                        )}
                    </BottomContainer>
                ) : (
                    ''
                )}
            </TextContainer>

            {chunkedCards.length > 1 ? (
                <SimpleSlider swiperOptions={swiperConfig}>
                    {chunkedCards.map((slide, i) => (
                        <SwiperSlide key={i}>
                            <CardsContainer length={slide.length}>
                                {slide.map((card, i) => (
                                    <PrimaryCard key={i} {...card} />
                                ))}
                            </CardsContainer>
                        </SwiperSlide>
                    ))}
                </SimpleSlider>
            ) : (
                chunkedCards.map((slide, i) => (
                    <CardsContainer key={i} length={slide.length}>
                        {slide.map((card, i) => (
                            <PrimaryCard key={i} {...card} />
                        ))}
                    </CardsContainer>
                ))
            )}
        </Container>
    );
};

export default WhyChooseSection;
