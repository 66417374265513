import React from 'react';
import { Container, CardsList } from './SupportSection.styled';
import PrimaryCard, { IPrimaryCard } from 'src/components-new/blocks/PrimaryCard/PrimaryCard';
import { BaseOffsetsUI, IMetrika } from '@/interfaces';

export interface SupportSectionProps extends BaseOffsetsUI, IMetrika {
    title: string | '';
    cards: IPrimaryCard[] | [];
}

const SupportSection: React.FC<SupportSectionProps> = ({ title, cards, topOffset, bottomOffset }) => {
    return (
        <>
            <Container topOffset={topOffset} bottomOffset={bottomOffset}>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <CardsList>
                    {cards?.map((card, i) => (
                        <PrimaryCard key={i} {...card} />
                    ))}
                </CardsList>
            </Container>
        </>
    );
};

export default SupportSection;
