import React from 'react';
import { Container, Wrapper, Title, Text, BackgroundPicture, Angle, Buttons, Button } from './Card4.styled';
import { BaseOffsetsUI, BaseUI, IMetrika, LinkI, ModalBtnI } from '@/interfaces';
import Link from 'next/link';
import { PictureType } from '@/components/common/Picture/Picture';
import AngleIcon from '@/icons/cards/new/angle.svg';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';

export interface Card4Props extends BaseUI, BaseOffsetsUI, IMetrika {
    title?: string;
    text?: string;
    description?: string;
    links?: LinkI[];
    modal?: ModalBtnI[];
    bgImage?: PictureType;
    link?: LinkI;
    angles?: string[];
}

const Card4: React.FC<Card4Props> = ({ title, text, description, bgImage, link, angles, links, modal, metrika, metriksSample }) => {
    const Card = (
        <Container>
            {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
            {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
            {description && <Text dangerouslySetInnerHTML={{ __html: description }} />}
            {(!!links?.length || modal) && (
                <Buttons>
                    {!!links?.length && links.map((link, index) => <Button key={index} {...link} metrika={metrika} />)}
                    {modal &&
                        modal.map(({ id, title, optionalId }, index) => (
                            <ModalBtn
                                className={'card-button'}
                                key={index}
                                id={id}
                                as={'button'}
                                clickOnMapButton={metriksSample}
                                optionalId={optionalId}
                            >
                                {title}
                            </ModalBtn>
                        ))}
                </Buttons>
            )}
            <Wrapper>
                {bgImage && <BackgroundPicture {...bgImage} alt={title} objectFit="cover" />}
                {angles?.map((angle, index) => {
                    return (
                        <Angle key={index} className={angle}>
                            <AngleIcon />
                        </Angle>
                    );
                })}
            </Wrapper>
        </Container>
    );

    return (
        <>
            {link ? (
                <Link href={link.href} passHref prefetch={false}>
                    <a target={link.isForeign ? '_blank' : undefined}>{Card}</a>
                </Link>
            ) : (
                <>{Card}</>
            )}
        </>
    );
};

export default Card4;
