import React from 'react';
import { Container, Wrapper, Icon, Title, Angle, Logo, ArrowIcon } from './Card1.styled';
import { BaseOffsetsUI, BaseUI, IMetrika, IMetrikaSample, LinkI } from '@/interfaces';
import AngleIcon from '@/icons/cards/new/angle.svg';
import Link from 'next/link';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';
import { clearFromNBSP, sendMetrik } from '@/helpers';
import useIsMatches from '@/hooks/useIsMatches';
import { SvgElements } from '@/helpers/icons';
import Arrow from '@/icons/arrow.svg';

export interface Card1Props extends BaseUI, BaseOffsetsUI, IMetrika {
    title?: string;
    image?: string;
    link?: Omit<LinkI, 'text'>;
    angles?: string[];
    modalId?: string;
    clickOnWrapper?: IMetrikaSample;
    iconName?: string;
    hasArrowIcon?: boolean;
}

const Card1: React.FC<Card1Props> = ({
    className,
    title,
    image,
    link,
    angles,
    modalId,
    metrika,
    clickOnWrapper,
    iconName,
    hasArrowIcon = false
}) => {
    const isMobile = useIsMatches('md');

    const Card = (
        <Container
            as={link?.href && 'a'}
            className={className}
            onClick={(e: React.MouseEvent) => {
                (metrika && metrika(e)) ||
                    (clickOnWrapper &&
                        sendMetrik(clickOnWrapper.event, clickOnWrapper.category, {
                            [clickOnWrapper.action]: clearFromNBSP(title)
                        }));
            }}
        >
            <Wrapper>
                {isMobile && image && <Icon src={image} alt={title} objectFit="contain" width={52} height={52} quality={95} />}

                {angles?.map((angle, index) => {
                    return (
                        <Angle key={index} className={angle}>
                            <AngleIcon />
                        </Angle>
                    );
                })}
            </Wrapper>
            {iconName && <Logo>{SvgElements[iconName]}</Logo>}
            {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
            {hasArrowIcon && (
                <ArrowIcon>
                    <Arrow />
                </ArrowIcon>
            )}
            {!isMobile && image && <Icon src={image} alt={title} objectFit="contain" width={160} height={160} quality={95} />}
        </Container>
    );

    return (
        <>
            {link ? (
                <Link href={link.href} target="_blank" passHref prefetch={false}>
                    {Card}
                </Link>
            ) : modalId ? (
                <ModalBtn id={!modalId ? 'ApplicationForm3' : modalId} as="div">
                    {Card}
                </ModalBtn>
            ) : (
                <>{Card}</>
            )}
        </>
    );
};

export default Card1;
