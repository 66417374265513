import styled from 'styled-components';
import { color, font, mediaBreakpointUp, mediaBreakpointDown, hover } from '@/style/mixins';
import Image from '@/components/common/Image/Image';

export const Icon = styled(Image)`
    flex-shrink: 0;
    width: 52px;
    height: 52px;
    user-select: none;
    transition: transform 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        align-self: flex-end;
        width: 100px;
        height: 100px;
    }

    ${mediaBreakpointUp('xl')} {
        width: 160px;
        height: 160px;
        contain: content;
    }
`;

export const Logo = styled.span`
    display: none;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const ArrowIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin-left: auto;

    & > svg {
        stroke: currentColor;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease-in-out;
    }
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${mediaBreakpointDown('md')} {
        align-items: center;
        gap: 8px;
    }

    ${mediaBreakpointUp('md')} {
        justify-content: space-between;
    }

    ${hover(`
        ${Icon} {
            transform: scale(1.04);
        }
        
        ${ArrowIcon} {
            svg {
                transform: translateX(8px);
            }
        }
    `)};
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    background-color: ${color('white')};
    border-radius: 20px;

    ${mediaBreakpointUp('md')} {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 24px;
        border-radius: 14px;
    }
`;

export const Title = styled.p`
    word-break: normal;
    position: relative;
    ${font('h3')};
    user-select: none;

    ${mediaBreakpointDown('md')} {
        text-align: center;
    }
`;

export const Angle = styled.div`
    position: absolute;
    z-index: 1;
    display: block;
    width: 32px;
    height: 32px;
    user-select: none;

    ${mediaBreakpointDown('xl')} {
        display: none;
    }

    &.top-left {
        top: -1px;
        left: -1px;
        transform: rotate(90deg);
    }

    &.top-right {
        top: -1px;
        right: -1px;
        transform: rotate(180deg);
    }

    &.bottom-left {
        bottom: -1px;
        left: -1px;
    }

    &.bottom-right {
        bottom: -1px;
        right: -1px;
        transform: rotate(-90deg);
    }

    svg {
        path {
            fill: ${color('Bg-Light')};
        }
    }
`;
